import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

/*--------------------
* Brand Slider
----------------------*/
export default function BrandSlider() {
  return (
    <>
      <div className="bg-slate-900 py-8">
        <div className="container">
          <h4 className="text-[25px] md:text-[30px] lg:text-[35px] m-0 text-white font-[600]">
            Helen of Baltimore &gt; Helen of Troy!
          </h4>
        </div>
      </div>
    </>
  );
}
