import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";

/*--------------------
* Testimonial Section
----------------------*/
export default function Testimonial() {
  const testimonail_list = [
    {
      id: 1,
      image: "img/avatar-1.jpg",
      author_name: "Steve",
      position: "Just some idiot",
      description:
        "Helen let's me be me, she's the best.  Lots of fun, good kisser, and fucking awesome!  She has brightened my day every day since I've met her.",
    },
    {
      id: 2,
      image: "img/avatar-2.jpg",
      author_name: "Helen of Troy",
      position: "The Inferior Helen",
      description:
        "Sadly, I must admit that Helen of Baltimore is far superior to me!  I conceed defeat, she is just too awesome, and the superior Helen.",
    },
    {
      id: 3,
      image: "img/avatar-3.jpg",
      author_name: "George Washington",
      position: "First US President",
      description:
        "Helen is fucking awesome.  When I faced accusations of being doing bad things and being a menace to society Helen saved me from being deported",
    },
    {
      id: 4,
      image: "img/avatar-4.jpg",
      author_name: "Terry T. Ceratops",
      position: "Yup a fucking dinosaur",
      description:
        "I don't know what I was thinking escaping the exhibit, there are so many crazy people out there.  But because Helen is awesome she rescued me, at her own peril",
    },
    {
      id: 6,
      image: "img/daisy.jpg",
      author_name: "Miss Daisy",
      position: "totally not scammed",
      description:
        "I got to keep my $3,500 because Helen is so fucking awesome.  I have absolutely no idea what I would have done without her",
    },
  ];

  return (
    <>
      <section className="section testimonial-section">
        <div className="container">
          <div className="grid section-heading">
            <div className="lg:col-span-6 text-center mx-auto">
              <h3>
                <span>Testimonial</span>
              </h3>
            </div>
          </div>
          <Swiper
            spaceBetween={30}
            slidesPerView={2}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            pagination={{ clickable: true }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              992: {
                slidesPerView: 2,
              },
            }}
          >
            {testimonail_list.map((val, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className="feature-box-03">
                    <div className="feature-img">
                      <img src={val.image} title={val.image} alt={val.image} />
                    </div>
                    <div className="feature-content">
                      <div className="icons">
                        <i className="fas fa-quote-left"></i>
                      </div>
                      <p>{val.description}</p>
                      <h6>{val.author_name}</h6>
                      <span>{val.position}</span>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
    </>
  );
}
