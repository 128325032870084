/*--------------------
* Contact Section
----------------------*/

export default function Contact() {
  return (
    <>
      <section
        data-scroll-data="4"
        id="contactus"
        className="section contactus-section bg-slate-900"
        style={{
          backgroundImage: "url(img/effect/bg-effect-3.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-6 ">
              <div className="p-[25px] md:p-[35px] bg-white">
                <h6 className="text-[32px] font-[600] text-black mb-[5px]">
                  Get in touch
                </h6>
                <p className="text-[18px] mb-[30px]">
                  Helen would love to hear from you. Please fill out the form.
                </p>
                <p className="text-[26px] mb-[30px]">
                  Actually, no. She absolutely fucking does not want to hear
                  from you, so we removed the damn form.
                </p>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6 flex">
              <div className="lg:max-w-[410px] w-full lg:ml-auto pt-[50px] lg:pt-0">
                <div className="pb-10">
                  <img
                    className="w-full"
                    src="img/IMG_9861.jpg"
                    title=""
                    alt=""
                  />
                </div>
                <ul className="contact-infos">
                  <li>
                    <div className="icon bg-1">
                      <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="col">
                      <h5>Phone</h5>
                      <p>1.866.FUC.KOFF</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon bg-2">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="col">
                      <h5>Mail</h5>
                      <p>fuckoff@helenisfuckingawesome.com</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
