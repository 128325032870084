import Header from "../Header/header";
import Banner from "../Banner/banner";
import BrandSlider from "../Brand/brand";
import SkillList from "../Skills/skill";
import Testimonial from "../Testimonial/testimonial";
import Contact from "../Contact/contact";
import Footer from "../Footer/footer";
import Intrested from "../Intrested/intrested";

/*--------------------
* Building Krinky
----------------------*/
export default function Krinky() {
  return (
    <>
      <Header />
      <main className="wrapper">
        <Banner />
        <BrandSlider />
        <SkillList />
        <Intrested />
        <Testimonial />
        <Contact />
      </main>
      <Footer />
    </>
  );
}
